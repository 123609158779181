<template>
  <div>
    <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="user.status === 'on_hold'">
      {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
    </vs-alert>
    <div class="flex justify-between">
      <span class="font-semibold"></span>
      <span v-show="$route.name !== 'merchant-cash-collections-view'" class="font-medium text-primary cursor-pointer">
        <vs-dropdown v-show="user.status == 'active'"
          vs-trigger-click
          ref="card"
          class="dropdown dd-actions cursor-pointer"
          :class="tourClosed ? 'step-selector' : ''"
        >
          <vs-button
              color="primary"
              class="px-4 py-3 rounded-lg"
              >
            <div class="flex items-center">
              <span class="text-base font-medium leading-tight">{{$t('Create Order')}}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-2 mt-1"/>
            </div>
          </vs-button>
          <vs-dropdown-menu class="add-shipment-dropdown w-64">
            <vs-dropdown-item class="step-selector-2" @click="redirectToCreateOrder('delivery-orders')">
              <span class="flex items-center text-blue-900 hover:text-primary">
                <feather-icon
                  icon="TruckIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{$t('Delivery Order')}}</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="redirectToCreateOrder('returns')">
                <span class="flex items-center text-blue-900 hover:text-primary">
                  <feather-icon
                    icon="PackageIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>{{$t('Return Order')}}</span>
                </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="redirectToCreateOrder('exchanges')">
              <span class="flex items-center">
                <feather-icon
                  icon="RepeatIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{$t('Exchange Order')}}</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="redirectToCreateOrder('cash-collections')">
              <span class="flex items-center">
                <feather-icon
                  icon="DollarSignIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{$t('Cash Collection Order')}}</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="uploadShipments()">
              <span class="flex items-center">
                <feather-icon
                  icon="UploadIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{$t('Multiple Orders')}}</span>
              </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </span>
      <span v-show="$route.name === 'merchant-cash-collections-view' && user.status === 'active'" class="font-medium text-primary cursor-pointer">
        <span @click="redirectToCreateOrder('cash-collections')" class="font-medium text-primary cursor-pointer">
          <vs-button
            color="primary"
            icon-pack="feather"
            icon="icon-plus"
            size="large"
          >
          </vs-button>
        </span>
      </span>
    </div>
    
    <add-cash-collection :governorates="governorates" :type="type" :orderPrice="orderPrice" 
      :cashCollectionModal="cashCollectionModal" @cashCollectionModal="cashCollectionModal = $event" :shipment="shipment" @addCashCollection="addCashCollection"></add-cash-collection>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData.js'
import AddCashCollection from './AddCashCollection.vue'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import common from '../../../assets/utils/common.js'

export default {
  props: ['tour', 'tourClosed', 'user'],
  data () {
    return {
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      totalPackages: [
        {
          package_size: '',
          description: '',
          fragile: false
        }
      ],
      pickupPoints: [],
      merchantID: '',
      shipmentModal: false,
      selectionInProgress: false,
      cashCollectionModal: false,
      shipmentURL: 'api/v1/delivery-orders/?ref=webapp',
      shipment: {
        promoCode: '',
        phone: '',
        customer_city: '',
        customer_governorate: '',
        number_of_packages: 1,
        cash_amount: 0,
        is_customer_allowed_to_open_packages: false,
        subscriptionId: '',
        pickupPoint: '',
        is_counter_dropoff: 2,
        merchant_order_reference: '',
        order_notes: '',
        declared_value: 0,
        address: {
          customerZone: '',
          line_1: '',
          zone: ''
        },
        service_level: {}
      },
      packages: [],
      subscriptionId: '',
      serviceLevels: [],
      packageSizes: [
        {
          description: 'Small Flyer (20x25cm)',
          id: 1,
          name: 'Small Flyer (20x25cm)',
          short_code: 'S-FLY'
        },
        {
          description: 'Medium Flyer (40x40cm)',
          id: 2,
          name: 'Medium Flyer (40x40cm)',
          short_code: 'M-FLY'
        },
        {
          description: 'Large Flyer (45x50cm)',
          id: 3,
          name: 'Large Flyer (45x50cm)',
          short_code: 'L-FLY'
        },
        {
          description: 'Extra Large Flyer (50x60cm)',
          id: 4,
          name: 'Extra Large Flyer (50x60cm)',
          short_code: 'XL-FLY'
        }
      ],
      governorates: [],
      cities: [],
      zones: [],
      type: 'Add Delivery Order',
      estimatePrice: 0,
      file_URl: '',
      orderPrice: {},
      merchantName: '',
      merchantEmail: '',
      checkValidation: false,
      merchant: {}
    }
  },
  watch: {
    'tourClosed' () {
      this.$emit('tour', '')
    },
    'tour' () {
      if (document.querySelector('.step-selector')) {
        document.querySelector('.step-selector').addEventListener('click', () => {
        })
      }
    }
  },
  methods: {
    canEstimatePriceDelivery (shipment) {
      if (shipment.customer_governorate && this.packages.length > 0 && this.packages.every(packageItem => packageItem.package_size)) {
        return true
      }
    },
    canEstimatePriceCash (shipment) {
      if (shipment.customer_governorate && shipment.cash_amount !== null) {
        return true
      }
    },
    loadServicesAvailability (id) {
      sendRequest(true, false, this, `api/v1/services-availability/?governorate_id=${id}&subscription_id=${this.subscriptionId}`, 'get', null, true, 
        (response) => {
          const serviceLevels = response.data.map(element => element.service_level)
          const key = 'id'

          this.serviceLevels = [...new Map(serviceLevels.map(item => [item[key], item])).values()]
          this.serviceLevels = this.serviceLevels.reverse()
        }
      )
    },
    loadPackagesSize () {
      sendRequest(true, false, this, 'api/v1/package-sizes/', 'get', null, true, 
        (response) => {
          const data = response.data
          const time = new Date()
          const PackageSizesDataTime = {data, time}
          localStorage.setItem('packages', JSON.stringify(PackageSizesDataTime))
        }
      )
    },
    loadPickupPoints () {
      sendRequest(true, false, this, `api/v1/merchants/${this.merchantID}/pickup-points/?limit=60`, 'get', null, true, 
        (response) => {
          this.pickupPoints = response.data.results
          this.pickupPoints.forEach(element => {
            element.fullAddress = element.address.line_1 + element.address.line_2 + element.address.zone.name
            if (element.is_default) {
              this.shipment.pickupPoint = element
            }
          })
        }
      )
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        const gizaGov = results.data.filter(item => item.id === 16)[0]
        this.governorates = results.data.toSpliced(1, 0, gizaGov)
        this.governorates = [...new Set(this.governorates)]
      })
    },
    getTemplate () {
      sendRequest(false, false, this, 'api/v1/orders-upload/', 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url
        }
      )
    },
    getMerchantData () {
      const merchantData = common.checkMerchantInLocalStorage(this)
      let user = {}
      merchantData.then(results => {
        user = results.merchantData
        this.merchant = user
        this.merchantID = user.id
        this.merchantName = user.name
        this.subscriptionId = user.subscription.id
        if (this.user.status === 'active') {
          this.loadPickupPoints()
        }
      })
    },
    redirectToCreateOrder (tab) {
      this.$router.push({
        name: 'merchant-create-order',
        query: {
          tab
        }
      }).catch(() => {})
    },
    openCashCollectionModal (type) {
      this.getMerchantData()
      this.type = type
      this.orderPrice = {}
      this.loadGovernorates()
      this.cities = []
      this.zones = []
      this.shipment = {
        cash_amount: 0,
        address: {
          line_1: '',
          zone: ''
        }
      }
      this.shipmentModal = false
      this.cashCollectionModal = true
    },
    uploadShipments () {
      this.$router.push({
        name: 'upload-order'
      }).catch(() => {})
    },
    addShipment () {
      setTimeout(() => {
        const orderData = {
          customer: {
            full_name: this.shipment.full_name,
            phone: this.shipment.phone,
            secondary_phone: this.shipment.secondary_phone ? this.shipment.secondary_phone : null,
            address: {
              line_1: this.shipment.address.line_1,
              line_2: this.shipment.address.line_2,
              line_3: this.shipment.address.line_3,
              zone: this.shipment.address.customerZone ? this.shipment.address.customerZone.id : '',
              zip_code: this.shipment.customer_zip_code
            }
          },
          is_counter_dropoff: this.shipment.is_counter_dropoff === 1,
          merchant_order_reference: this.shipment.merchant_order_reference,
          is_customer_allowed_to_open_packages: this.shipment.is_customer_allowed_to_open_packages
        }
        if (!orderData.is_counter_dropoff && this.type !== 'Add Return') {
          orderData.pickup_point = this.shipment.pickupPoint ? this.shipment.pickupPoint.id : null
        }
        if (this.shipment.serviceLevel && this.serviceLevels.length !== 0) {
          orderData.service_level = this.subscriptionId === 2 ? this.serviceLevels[0].id : this.shipment.serviceLevel.id
        }
        if (this.type === 'Add Return') {
          this.shipmentURL = 'api/v1/returns/?ref=webapp'
          orderData.service_level = this.shipment.serviceLevel.id
          orderData.cash_amount = Number(this.shipment.cash_amount)
        } else {
          orderData.declared_value = Number(this.shipment.declared_value)
          orderData.cash_amount = Number(this.shipment.cash_amount)
        }
        orderData.packages = []
        this.packages.forEach(element => {
          orderData.packages.push({
            package_size: element.package_size ? element.package_size.id : '',
            description: element.description,
            fragile: element.fragile
          })
        })
        if (this.shipment.email !== '') {
          orderData.customer.email = this.shipment.email
        }
        if (this.shipment.order_notes !== null && this.shipment.order_notes !== '') {
          orderData.order_notes = this.shipment.order_notes
        }
        orderData.discount_code = this.shipment.promoCode
        if (!this.isOnline) this.shipmentModal = false
        sendRequest(false, false, this, this.shipmentURL, 'post', orderData, true,
          () => {
            localStorage.removeItem('orderCreationForm')
            if (this.releaseVersion !== 'staging') {
              this.$mixpanel.track(this.type === 'Add Return' ? 'Complete New Return' : 'Complete New Delivery Order', {
                merchant_name: this.merchantName,
                merchant_email: this.merchantEmail,
                source: /(android)/i.test(navigator.userAgent)
              })
            }

            // MIXPANEL
            if (!this.selectionInProgress) {
              if (this.releaseVersion !== 'staging') {
                this.$mixpanel.track('RECURRING_CUSTOMER', {
                  merchant_name: this.merchantName,
                  merchant_email: this.merchantEmail,
                  source: /(android)/i.test(navigator.userAgent)
                })
              }
            }
            this.shipmentModal = false
            this.$vs.notify({
              color:'success',
              title:i18nData[this.$i18n.locale]['Success'],
              text:this.type === 'Add Delivery Order' ? i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been created successfully!'] : i18nData[this.$i18n.locale]['Return'] + i18nData[this.$i18n.locale][' has been created successfully!'],
              position: 'top-center'
            })
            this.shipmentModal = false
            if (this.type === 'Add Delivery Order') {
              if (this.$route.name === 'merchant-shipments') {
                this.$emit('loadShipments')
              } else {
                this.$router.push({
                  name: 'merchant-shipments'
                }).catch(() => {})
              }
            } else if (this.type === 'Add Return') {
              if (this.$route.name === 'merchant-returns-view') {
                this.$emit('loadShipments')
              } else {
                this.$router.push({
                  name: 'merchant-returns-view'
                }).catch(() => {})
              }
            }
          }
        )
      }, 100)
    },
    addCashCollection () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const cashCollectionData = {
            customer: {
              full_name: this.shipment.full_name,
              phone: this.shipment.phone,
              email: this.shipment.email,
              secondary_phone: this.shipment.secondary_phone ? this.shipment.secondary_phone : null,
              address: {
                line_1: this.shipment.address.line_1,
                line_2: this.shipment.address.line_2,
                zone: this.shipment.address.customerZone ? this.shipment.address.customerZone.id : ''
              }
            },
            amount: this.shipment.cash_amount
          }
          if (!this.isOnline) this.cashCollectionModal = false
          sendRequest(false, false, this, 'api/v1/cash-collections/', 'post', cashCollectionData, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['Cash Collection'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.cashCollectionModal = false

              if (this.$route.name === 'merchant-cash-collections-view') {
                this.$emit('loadShipments')
              } else {
                this.$router.push({
                  name: 'merchant-cash-collections-view'
                }).catch(() => {})
              }
            }
          )
        }
      })
    }
  },
  components: {
    vSelect,
    AddCashCollection,
    ShipbluPrompt
  },
  created () {
    if (localStorage.getItem('packages') ===  'null') {
      this.loadPackagesSize()
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";

.vs-con-input-label.is-label-placeholder {
  margin-top: 10px;
}
.dropdown {
  .vs-dropdown--item-link span {
    color: #0A3266 !important;
  }
}
.new-img .vs-dropdown--item-link{
  position: unset;
}
[dir=rtl] .shepherd-modal-overlay-container {
  right: -5px !important;
}
</style>
